<template>
  <div class="saleDetailReceive">
    <el-form class="form" label-width="auto">
      <el-form-item>
        <h1 style="margin-right: 20px;">{{ data.sale.id ? '编辑' : '新增' }}销售明细（到达）</h1>
        <el-button-group>
          <el-button @click="router.go(-1)">返回</el-button>
          <el-button type="info" @click="data.sale = {}" v-show="false">清空</el-button>
          <el-button type="primary" @click="receive()">保存</el-button>
        </el-button-group>
      </el-form-item>
      <el-row :gutter="20">
        <el-col :span="6">
          <el-form-item label="发货日期">
            <el-date-picker style="width: 100%;" v-model="data.sale.sendDate" type="date" @change="handleChangeSendDate" disabled />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="到货日期">
            <el-date-picker style="width: 100%;" v-model="data.sale.receiveDate" type="date" />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="仓储">
            <el-select v-model="data.sale.storage" filterable disabled @change="findTransDedSaleFormula">
              <el-option v-for='storageItem in show.storageList' :key="storageItem.id" :label="storageItem.value" :value="storageItem.value" disaled />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="客户">
            <el-select v-model="data.sale.custom" filterable disabled>
              <el-option v-for='customItem in show.customList' :key="customItem.id" :label="customItem.value" :value="customItem.value" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="运输公司">
            <el-select v-model="data.sale.transportCompany" filterable disabled>
              <el-option v-for='transportCompanyItem in show.transportCompanyList' :key="transportCompanyItem.id" :label="transportCompanyItem.value" :value="transportCompanyItem.value" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="车号">
            <!-- <el-input v-model="data.sale.truckNumber" :max="100" /> -->
            <el-autocomplete style="width: 100%;" v-model="data.sale.truckNumber" :fetch-suggestions="getTruckNumbers" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="备注信息">
            <el-input v-model="data.sale.note" :max="100" disabled />
          </el-form-item>
        </el-col>
        <el-divider content-position="left">数量</el-divider>
        <el-col :span="8">
          <el-form-item label="重车数量">
            <el-input-number disabled style="width: 100%;" :controls="false" :precision="2" v-model="data.sale.sendQuantityGross" @change="updateSendQuantityGross" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="皮重数量">
            <el-input-number disabled style="width: 100%;" :controls="false" :precision="2" v-model="data.sale.sendQuantityTare" @change="updateSendQuantityTare" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="涨吨数量">
            <el-input-number style="width: 100%;" :controls="false" :precision="2" v-model="data.sale.sendExpandedQuantity" @change="updateSendExpandedQuantity" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-tooltip effect="dark" content="原始的出库数量（重车数量-皮重数量-涨吨数量）" placement="bottom">
            <el-form-item label="出库数量">
              <el-input-number disabled style="width: 100%;" :controls="false" :precision="2" v-model="data.sale.sendQuantityInitial" @change="updateSendQuantityInitial" />
            </el-form-item>
          </el-tooltip>
        </el-col>
        <el-col :span="8">
          <el-form-item label="退车数量">
            <el-input-number style="width: 100%;" :controls="false" :precision="2" v-model="data.sale.receiveQuantityBack" @change="updateReceiveQuantityBack" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-tooltip effect="dark" content="本车最终核算的扣减库存数（出库数量-退车数量）" placement="bottom">
            <el-form-item label="库存扣减">
              <el-input-number disabled style="width: 100%;" :controls="false" :precision="2" v-model="data.sale.sendQuantity" @change="updateSendQuantity" />
            </el-form-item>
          </el-tooltip>
        </el-col>
        <el-col :span="8">
          <el-tooltip effect="dark" content="上游运输后原始的到达数量，计算亏吨用，退车不影响此数量" placement="bottom">
            <el-form-item label="到货数量">
              <el-input-number style="width: 100%;" :controls="false" :precision="2" v-model="data.sale.receiveQuantityOriginal" @change="updateReceiveQuantityOriginal" />
            </el-form-item>
          </el-tooltip>
        </el-col>
        <el-col :span="8">
          <el-tooltip effect="dark" content="出库数量+涨吨数量-到货数量" placement="bottom">
            <el-form-item label="运输亏吨">
              <el-input-number disabled style="width: 100%;" :controls="false" :precision="2" v-model="data.sale.lossQuantity" />
            </el-form-item>
          </el-tooltip>
        </el-col>
        <el-col :span="8">
          <el-tooltip effect="dark" content="客户最终留下的数量（到货数量-退车数量）" placement="bottom">
            <el-form-item label="最终验收">
              <el-input-number disabled style="width: 100%;" :controls="false" :precision="2" v-model="data.sale.receiveQuantity" />
            </el-form-item>
          </el-tooltip>
        </el-col>
        <el-col :span="24">
          <el-form-item label="销售品名">
            <!-- <el-autocomplete style="width: 100%;" v-model="data.sale.customNote" :fetch-suggestions="filterCustomNoteList" value-key="customNote" @select="handleSelect" placeholder="选择提示，会自动补全仓储和配比" /> -->
            <el-select v-model="data.sale.customNote" @change="handleCustomNoteSelect" filterable :placeholder="data.sale.sendDate ? '请选择' : '请先选择发货日期'">
              <el-option v-for='customNote in show.customNoteList' :key="customNote.id" :label="customNote.customNote" :value="customNote.customNote" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="配比" v-show="false">
            <el-table :data="data.sale.saleMixDetailList" border height="210px">
              <el-table-column label="" width="80" header-align="center" align="center">
                <template #default="scope">
                  <el-link type="danger" @click="delRow(scope.row)" v-if="scope.row.product">删除</el-link>
                </template>
              </el-table-column>
              <el-table-column prop="product" label="煤矿品名" header-align="center">
                <template #default="scope">
                  <el-select style="width: 100%;" v-model="scope.row.product" filterable @change="checkRowProduct(scope.row)">
                    <el-option v-for='productItem in show.productList' :key="productItem.id" :label="productItem.value" :value="productItem.value" />
                  </el-select>
                </template>
              </el-table-column>
              <el-table-column prop="mixNumber" label="占比" header-align="center">
                <template #default="scope">
                  <el-input-number style="width: 100%;" :controls="false" :precision="2" v-model="scope.row.mixNumber" @change="computeSaleMix" />
                </template>
              </el-table-column>
              <el-table-column prop="sendQuantity" label="库存扣减" header-align="center">
                <template #default="scope">
                  <el-input-number style="width: 100%;" :controls="false" :precision="2" v-model="scope.row.sendQuantity" @change="changeMixQuantity" />
                </template>
              </el-table-column>
            </el-table>
          </el-form-item>
        </el-col>
        <el-divider content-position="left">运费</el-divider>
        <el-col :span="8">
          <el-form-item label="运输单价">
            <el-input-number style="width: 100%;" :controls="false" :precision="2" v-model="data.sale.transportPrice" @change="computeTransportAmount" disabled />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="运费扣除">
            <el-input-number style="width: calc(100% - 70px)" :controls="false" :precision="2" v-model="data.sale.transportDeductionAmount" @change="computeTransportAmount" />
            <el-link type="primary" style="margin-left: 20px;" @click="show.transDedAmountFormulaDialog = true">公式</el-link>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="实付运费">
            <el-input-number style="width: 100%;" :controls="false" :precision="2" v-model="data.sale.transportAmount" />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>

  <el-dialog v-model="show.transDedAmountFormulaDialog" title="选择运费扣除公式" width="650">
    <el-table :data="show.transDedAmountFormulaList" :show-header="false">
      <el-table-column prop="name">
        <template #default="scope">
          <el-text v-show="!scope.row.default">{{ scope.row.name }}</el-text>
          <el-text v-show="scope.row.default" type="primary">{{ '【默认】' + scope.row.name }}</el-text>
        </template>
      </el-table-column>
      <el-table-column prop="btn" width="200">
        <template #default="scope">
          <el-button-group>
            <el-button type="primary" @click="useTransDedAmountFormula(scope.row)">使用</el-button>
            <el-button @click="useTransDedAmountFormulaAndSetDefault(scope.row)">并标记默认</el-button>
          </el-button-group>
        </template>
      </el-table-column>
    </el-table>
  </el-dialog>
</template>

<script setup>
import { reactive, onUnmounted } from 'vue'
import { useRouter } from 'vue-router'
import { ElMessage } from 'element-plus'
import api from '../../apis/api'
import cache from '../../utils/cache'
import util from '../../utils/util'
import math from '../../utils/math'

const router = useRouter()
const show = reactive({
  customList: [],
  storageList: [],
  productList: [],
  transportCompanyList: [],
  customNoteList: [],
  transDedAmountFormulaList: [],
  transDedAmountFormulaDialog: false,
})
const pageParam = cache.getObject(cache.keys.pageParam + "saleDetailReceive")
const data = reactive({
  sale: pageParam ? JSON.parse(JSON.stringify(pageParam)) : { saleMixDetailList: [] }
})
data.sale.saleMixDetailList.push({})

api.get('/backend/dict/getByCode', { params: { code: 'custom' } }).then(res => {
  show.customList = res.dictList
})
api.get('/backend/dict/getByCode', { params: { code: 'storage' } }).then(res => {
  show.storageList = res.dictList
})
api.get('/backend/dict/getByCode', { params: { code: 'product' } }).then(res => {
  show.productList = res.dictList
})
api.get('/backend/dict/getByCode', { params: { code: 'transportCompany' } }).then(res => {
  show.transportCompanyList = res.dictList
})

const findTransDedSaleFormula = () => {
  const formulaParam = {
    model: 'transDedSale'
  }
  if (data.sale.storage) {
    formulaParam.defaultKey = data.sale.storage
  }
  api.get('/backend/formula/get', { params: formulaParam }).then(res => {
    show.transDedAmountFormulaList = res.list
    if (res.data && res.data.default) {
      for (let formula of show.transDedAmountFormulaList) {
        if (formula.id == res.data.default.id) {
          formula.default = true
        }
      }
    }
  })
}
findTransDedSaleFormula()

const handleChangeSendDate = () => {
  // 重新查询配比
  show.customNoteList = []
  if (data.sale.sendDate) {
    const useDate = util.parseTime(data.sale.sendDate, '{y}-{m}-{d}')
    api.get('/backend/customNoteToMix/all', { params: { useDate } }).then(res => {
      show.customNoteList = res.list
    })
  }
  // 清除已选择的配比
  data.sale.customNote = null
  data.sale.saleMixDetailList = [{}]
}

const delRow = (row) => {
  data.sale.saleMixDetailList = data.sale.saleMixDetailList.filter(item => item.product != row.product)
  computeSaleMix()
}

const checkRowProduct = (row) => {
  const l1 = data.sale.saleMixDetailList.length
  const l2 = data.sale.saleMixDetailList.filter(item => item.product != row.product).length
  if (l1 != l2 + 1) {
    ElMessage.error("煤矿品名重复")
    row.product = null
  } else {
    data.sale.saleMixDetailList.push({})
  }
}

const receive = () => {
  let sumSendQuantityMix = 0
  data.sale.saleMixDetailList.forEach(mixDetail => {
    if (mixDetail.sendQuantity && mixDetail.product) {
      sumSendQuantityMix = math.plus(sumSendQuantityMix, mixDetail.sendQuantity)
    }
  })
  if (sumSendQuantityMix != data.sale.sendQuantity) {
    ElMessage.error("配比数量不等于发货数量，请重新填写配比数量")
    return
  }
  const param = JSON.parse(JSON.stringify(data.sale))
  param.sendDate = util.parseTime(param.sendDate, '{y}-{m}-{d}')
  param.receiveDate = util.parseTime(param.receiveDate, '{y}-{m}-{d}')
  param.saleMixDetailList = data.sale.saleMixDetailList.filter(item => item.product && item.sendQuantity).map(item => {
    return {
      product: item.product,
      mixNumber: item.mixNumber,
      sendQuantity: item.sendQuantity,
    }
  })
  param.operateUser = null
  param.operateDatetime = null
  param.saleSampleId = null
  param.hasCheckTransportAmount = null
  if (!param.sendQuantityGross) {
    param.sendQuantityGross = 0
  }
  if (!param.sendQuantityTare) {
    param.sendQuantityTare = 0
  }
  if (!param.receiveQuantityOriginal) {
    param.receiveQuantityOriginal = 0
  }
  if (!param.receiveQuantityBack) {
    param.receiveQuantityBack = 0
  }
  api.post('/backend/sale/receive', param).then(() => {
    ElMessage.success('保存成功')
    router.go(-1)
  })
}

// const filterCustomNoteList = () => {
//   return show.customNoteList.filter(c => {
//     return c.customNote.indexOf(data.sale.customNote) >= 0
//   })
// }

// const handleSelect = (customNote) => {
//   data.sale.storage = customNote.storage
//   data.sale.saleMixDetailList = customNote.mixList
//   data.sale.saleMixDetailList.push({})
//   computeSaleMix()
// }

const handleCustomNoteSelect = (customNoteString) => {
  const customNote = show.customNoteList.filter(x => x.customNote == customNoteString)[0]
  data.sale.storage = customNote.storage
  data.sale.saleMixDetailList = customNote.mixList
  data.sale.saleMixDetailList.push({})
  computeSaleMix()
}

const computeSaleMix = () => {
  if (data.sale.saleMixDetailList == null) {
    return
  }
  const saleMixDetailList = data.sale.saleMixDetailList.filter(x => x.product)
  if (data.sale.sendQuantity && saleMixDetailList.length > 0) {
    let mixNumber = 0
    for (const i in saleMixDetailList) {
      const saleMixDetailItem = saleMixDetailList[i]
      mixNumber = math.plus(mixNumber, saleMixDetailItem.mixNumber || 0)
    }
    if (mixNumber > 0) {
      let sumQuantity = 0
      for (const i in saleMixDetailList) {
        const saleMixDetailItem = saleMixDetailList[i]
        if (!saleMixDetailItem.mixNumber) {
          saleMixDetailItem.sendQuantity = 0
        } else {
          if (i < saleMixDetailList.length - 1) {
            saleMixDetailItem.sendQuantity = math.halfUp(data.sale.sendQuantity * saleMixDetailItem.mixNumber / mixNumber, 2)
          } else {
            saleMixDetailItem.sendQuantity = math.plus(data.sale.sendQuantity, -sumQuantity)
          }
          sumQuantity = math.plus(sumQuantity, saleMixDetailItem.sendQuantity)
        }
      }
      ElMessage.success('已自动配比数量')
    }
  }
}

const changeMixQuantity = () => {
  for (const mixIndex in data.sale.saleMixDetailList) {
    const mixDetail = data.sale.saleMixDetailList[mixIndex]
    if (mixDetail.sendQuantity && mixDetail.product) {
      mixDetail.mixNumber = mixDetail.sendQuantity
    }
  }
}

const useTransDedAmountFormulaAndSetDefault = (formula) => {
  if (!data.sale.storage) {
    ElMessage.error('没有选择仓库，无法设置默认公式')
    return
  }
  api.post('/backend/formula/setDefault', {
    model: 'transDedSale',
    key: data.sale.storage,
    formulaId: formula.id
  }).then(() => {
    ElMessage.success('公式已设为' + data.sale.storage + '默认')
    useTransDedAmountFormula(formula)
  })
}

const useTransDedAmountFormula = (formula) => {
  try {
    let 结果 = null
    const 重车数量 = data.sale.sendQuantityGross
    const 皮重数量 = data.sale.sendQuantityTare
    const 出库数量 = data.sale.sendQuantityInitial
    const 涨吨数量 = data.sale.sendExpandedQuantity
    const 退车数量 = data.sale.receiveQuantityBack
    const 库存扣减 = data.sale.sendQuantity
    const 到货数量 = data.sale.receiveQuantityOriginal
    const 运输亏吨 = data.sale.lossQuantity
    const 最终验收 = data.sale.receiveQuantity
    console.info('重车数量', 重车数量, '皮重数量', 皮重数量, '出库数量', 出库数量, '涨吨数量', 涨吨数量, '退车数量', 退车数量, '库存扣减', 库存扣减, '到货数量', 到货数量, '运输亏吨', 运输亏吨, '最终验收', 最终验收)
    eval(formula.jsScript)
    if (结果 === null) {
      ElMessage.error('公式有误，请手动填写')
      return
    }
    data.sale.transportDeductionAmount = math.halfUp(结果, 2)
    computeTransportAmount()
    show.transDedAmountFormulaDialog = false
  } catch {
    ElMessage.error('公式有误，请手动填写')
  }
}

const updateSendQuantityGross = () => {
  data.sale.sendQuantityInitial = math.plus(data.sale.sendQuantityGross || 0, -data.sale.sendQuantityTare || 0, -data.sale.sendExpandedQuantity || 0)
  updateSendQuantityInitial()
}

const updateSendQuantityTare = () => {
  data.sale.sendQuantityInitial = math.plus(data.sale.sendQuantityGross || 0, -data.sale.sendQuantityTare || 0, -data.sale.sendExpandedQuantity || 0)
  updateSendQuantityInitial()
}

const updateSendQuantityInitial = () => {
  data.sale.sendQuantity = math.plus(data.sale.sendQuantityInitial || 0, -data.sale.receiveQuantityBack || 0)
  updateSendQuantity()
  data.sale.lossQuantity = math.plus(data.sale.sendQuantityInitial || 0, -data.sale.receiveQuantityOriginal || 0)
}

const updateReceiveQuantityBack = () => {
  data.sale.sendQuantity = math.plus(data.sale.sendQuantityInitial || 0, -data.sale.receiveQuantityBack || 0)
  updateSendQuantity()
  data.sale.receiveQuantity = math.plus(data.sale.receiveQuantityOriginal || 0, -data.sale.receiveQuantityBack || 0)
}

const updateSendQuantity = () => {
  computeSaleMix()
}

const updateSendExpandedQuantity = () => {
  data.sale.sendQuantityInitial = math.plus(data.sale.sendQuantityGross || 0, -data.sale.sendQuantityTare || 0, -data.sale.sendExpandedQuantity || 0)
  updateSendQuantityInitial()
  data.sale.lossQuantity = math.plus(data.sale.sendQuantityInitial || 0, data.sale.sendExpandedQuantity || 0, -data.sale.receiveQuantityOriginal || 0)
}

const updateReceiveQuantityOriginal = () => {
  data.sale.lossQuantity = math.plus(data.sale.sendQuantityInitial || 0, data.sale.sendExpandedQuantity || 0, -data.sale.receiveQuantityOriginal || 0)
  data.sale.receiveQuantity = math.plus(data.sale.receiveQuantityOriginal || 0, -data.sale.receiveQuantityBack || 0)
}

const computeTransportAmount = () => {
  if (data.sale.receiveQuantityOriginal && data.sale.transportPrice) {
    let transportAmount = math.plus(math.multiply(data.sale.receiveQuantityOriginal, data.sale.transportPrice), -(data.sale.transportDeductionAmount || 0))
    transportAmount = math.floor(transportAmount, -1)
    if (data.sale.transportAmount != transportAmount) {
      data.sale.transportAmount = transportAmount
      ElMessage.success('运费已自动更新（自动抹零）')
    }
  }
}

const getTruckNumbers = (queryString, callback) => {
  api.get('/backend/sale/getTruckNumbers', { params: { truckNumberLike: queryString } }).then(res => {
    callback(res.list.map(item => { return { value: item } }))
  })
}
</script>

<style lang="less">
.saleDetailReceive {
  margin: auto;
  max-width: 900px;
}
</style>